@use '../../scss/' as *;
/* Modal Popup  ----------
-------------------------*/
.modal-dialog {
    width: 100%;
    max-width: 500px;   
}
.modal-content{
    border-radius: 20px !important;
    border: none !important;
    box-shadow: 0px 3px 16px rgb(47 83 109 / 12%) !important;
    background:  #fff;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 500px;
}
.is_dark .modal-content{
    border-radius: 20px !important;
    border: none !important;
    box-shadow: 0px 3px 16px rgb(47 83 109 / 12%) !important;
    background:  $custom-black;
}

.modal-body {
    padding: 40px !important;
    .price {
        font-weight: 700;
        font-size: 14px;
        // color: var(--primary-color3);
    }
    .quantity{
        color: var(--color-1);
    }
    .btn.btn-primary{
        width: 100%;
        border-radius: 20px;
        font-weight: 700;
        font-size: 15px;
        line-height: 22px;
        padding: 10px 20px;
        background-color: var(--primary-color3) !important;
        @include transition3;
        outline: none;
        border: none ;
    }
}

.is_dark .modal-body {
    .price {
        color: var(--primary-color3);
    }
    .quantity{
        color: var(--primary-color2);
        &:focus{
            color: var(--primary-color);
        }
    }
}

.modal-content input,
.modal-content p {
    // margin-bottom: 10px;
}

.modal-body .btn.btn-primary:hover{
    opacity: 0.8;
}

.modal-content .close{
    overflow: hidden;
    height: 30px;
    width: 30px;
    border-radius: 50px;
    position: absolute;
    right: -15px;
    top: -15px;
    background-color: var(--primary-color3);
    border: none;
    outline: none;
    z-index: 10 !important;
    opacity: 1;
    box-shadow: 0px 3px 16px rgb(47 83 109 / 12%);
}

.modal-content .close:hover{
    opacity: 1;
}

.modal-content .close span{
    font-size: 15px;
    color: #fff;
}

.is_dark .modal-content .close{
    background-color: var(--primary-color3);
}

.modal-body h2{
    text-align: left;
    font-weight: 600;
    font-size: 22px;
}
.modal-body{
    .subTitle{
        font-size: 12px;
        margin-bottom: 30px;
        line-height: 15px;
    }
    .p-title{
        margin-bottom:0px ;
        line-height: 20px
    }
    .custom-mb-10{
        margin-bottom: 10px;
    }
    .cardRight{
        width: 100%;
        img{
            margin-bottom: 40px;
            border-radius: 15px;
        }
    }
    .cardLeft{
        width: 100%;
    }
    .spanMnur{
        font-size: 9px;
        font-weight: normal;
        position: absolute;
        top: -4px;
        padding-left: 4px;
    }
    .posRelative{
        position: relative;
    }
}

.modal{
    z-index: 999999 !important;
}

.modal-open .modal{
    overflow: hidden !important;
    padding-right: 0 !important;
}

.modal-dialog-centered{
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
    max-width: 500px;
    margin: 1.75rem auto;
}
.modal.fade.popup.show {
    padding-right: 0 !important;
}

//Responsive

@media only screen and (max-width: 767px){

    .modal-body{
        padding: 30px 25px !important;
    }
    .modal-body h2{
        text-align: left;
        font-weight: 700;
        font-size: 18px;
    }

    .modal-body{
        .subTitle{
            font-size: 12px;
            margin-bottom: 20px;
            line-height: 20px;
        }
        .p-title{
            margin-bottom:0px ;
            line-height: 20px
        }
        .custom-mb-10{
            margin-bottom: 10px;
        }
        .cardRight{
            width: 100%;
            img{
                margin-bottom: 40px;
                border-radius: 15px;
            }
        }
        .cardLeft{
            width: 100%;
        }
    }

}